<template>
  <el-dialog  title="临时服务人员"
              :close-on-click-modal="false"
              :visible.sync="isOpenModal"
              append-to-body
              width="1000px"
              @closed="closeModal">
    <div style="width: 100%">
      <ch-button @click="openAddTag">新增</ch-button>
      <ch-table :render-option="tableOption" :data="tableList"
                :loading="isTableLoading"
                :props="{width: '100%', height: '300px', size: 'mini'}">
        <template v-slot:available="scope">
          <div v-if="scope.row.isAvailable===1" class="circle circle_green"></div>
          <div v-else class="circle circle_red"></div>
        </template>
        <template v-slot:handle="scope">
          <ch-button type="link" @click="openEdit(scope.row)">编辑</ch-button>
          <ch-button type="link" v-if="scope.row.status===0" @click="handleRecoverStop(scope.row)">停用</ch-button>
          <ch-button type="link" v-if="scope.row.status===1" @click="handleRecoverStop(scope.row)">恢复</ch-button>
          <ch-button type="delete" v-if="scope.row.status===2" @click="toExamine(scope.row)">审核</ch-button>
          <ch-button type="delete" v-if="scope.row.canDelete===1 || scope.row.status===2 || scope.row.status===3" @click="handleDelete(scope.row)">删除</ch-button>
          <ch-button type="link" v-if="scope.row.status===0||scope.row.status===1" @click="()=>{
            $router.push({name:'serviceStaffTakeOrderRecord',query:{id:scope.row.id}})
            isOpenModal = false
          }">接单记录</ch-button>
          <ch-button type="link" v-if="scope.row.status===0||scope.row.status===1" @click="()=>{
            $router.push({name:'serviceStaffFlow',query:{id:scope.row.id}})
            isOpenModal = false
          }">资金流水</ch-button>
        </template>
      </ch-table>
    </div>
    <div slot="footer" class="flex_con">
      <ch-page
          type="old"
          ref="page"
          :getListFunction="getList"></ch-page>
    </div>
    <modal ref="tagsModal" :getListFunction="getList"></modal>
    <el-dialog  title="审核临时服务人员"
                :close-on-click-modal="false"
                :visible.sync="isOpenExamineModal"
                append-to-body
                width="500px"
                @closed="closeExamineModal">
      <ch-form
          :render-option="examineOption"
          :model="examineForm"
          ref="examineFormRef" style="width: 100%;"
          :rules="rulesExamine"
          :props="{ labelWidth: '120px', paddingRight: '10px' }"
      />
      <div slot="footer" class="flex_con">
        <ch-button type="cancel" @click="closeExamineModal">取消</ch-button>
        <ch-button type="ok" :loading="isExamineLoading" @click="handleExamine">确定</ch-button>
      </div>
    </el-dialog>
  </el-dialog>
</template>
<script>
import { handleDelete } from "@/utils/utils";

const modal = () => import("./modal.vue");
export default {
  components: {
    modal
  },
  data(){
    return {
      isOpenModal:false,
      isTableLoading:false,
      tableList:[],
      isOpenExamineModal:false,
      examineForm:{
        status: 0,
      },
      rulesExamine: {
        status: [{ required: true, message: "请选择状态", trigger: "change" },],
      },
      id: null,
      isExamineLoading: false,
      // 状态(0:正常，1:停用,2:待审核,3:不通过）
      statusList: ['正常','停用','待审核','不通过'],
    }
  },
  computed:{
    tableOption:function (){
      return [
        { column: "type", prop: "", type: "selection", width: "25px" },
        { column: "type", label: "序号", prop: "", type: "index", width: "70",},
        { column: "text", label: "姓名", prop: "name", showOverflowTooltip: true,},
        { column: "text", label: "手机号", prop: "phone", showOverflowTooltip: true,minWidth: '100px'},
        { column: "text", label: "服务分成比例", prop: "commissionRate", showOverflowTooltip: true,sign:'%',minWidth: '120px'},
        { column: "text", label: "增值分成比例", prop: "appreciationProportion", showOverflowTooltip: true,minWidth: '120px'},
        { column: "slot", label: "服务状态", slotName: "available"},
        { column: "text", label: "状态", prop: "_status"},
        { column: "text", label: "分佣比例%", prop: "commissionRate", showOverflowTooltip: true,props:{minWidth: '120px'}},
        { column: "text", label: "累计服务单数", prop: "finishOrderNum", showOverflowTooltip: true,props:{minWidth: '120px'}},
        { column: "text", label: "累计五星单数", prop: "acclaimOrderNum", showOverflowTooltip: true,props:{minWidth: '120px'}},
        { column: "text", label: "累计收入", prop: "totalIncome", showOverflowTooltip: true, sign: "元"},
        { column: "text", label: "可提现余额", prop: "canWithdrawalAmount", showOverflowTooltip: true, sign: "元",props:{minWidth: '120px'}},
        { column: "slot", label: "操作", slotName: "handle", width: "210",fixed: 'right'},
      ];
    },
    examineOption: function () {
      return [
        {type: "radio", label: '审核状态', prop: "status", option: [
            {label: "通过", value: 0},
            {label: "不通过", value: 1},
          ]}
      ]
    },
  },
  methods:{
    open(){
      this.isOpenModal = true
      this.getList()
    },
    getList(){
      this.isTableLoading = true;
      this.$refs?.page?.getServerList("/hm/serviceStaff/getTemporaryList").then((res) => {
        this.tableList = res.rows.map(item => {
          return {
            ...item,
            _status: this.statusList[item.status]
          }
        })
      }).finally(() => {this.isTableLoading = false});
    },
    openAddTag(){
      this.$refs?.tagsModal?.openAdd()
    },
    openEdit(row){
      this.$refs?.tagsModal?.openEdit(row)
    },
    handleDelete(row){
      handleDelete("/hm/serviceStaff/del/", row.id).then(() => {
        this.getList()
      })
    },
    // 恢复停用
    handleRecoverStop(row) {
      let text = row.status===0? '停服':'恢复'
      this.$confirm(`${text}本服务人员, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let status = row.status===0? 1:0
        this.$curl.post(`/hm/serviceStaff/updateStatus/${row.id}/${status}`)
            .then(() => {
              this.$message.success("操作成功！");
              this.getList()
            })
      })
    },
    closeModal(){
      this.isOpenModal = false
    },
    //审核
    toExamine(row) {
      this.isOpenExamineModal = true
      this.id = row.id
      this.$refs.examineFormRef.clearValidate()
    },
    closeExamineModal(){
      this.isOpenExamineModal = false
      this.examineForm = {
        status: 0,
      }
      this.id = null
    },
    handleExamine(){
      this.isExamineLoading = true
      this.$curl.get(`/hm/serviceStaff/temporaryAudit/${this.id}/${this.examineForm.status}`).then(() => {
        this.$message.success("审核通过！");
        this.getList()
        setTimeout(() => {
          this.closeExamineModal()
        })
      }).finally(() => {
        this.isExamineLoading = false
      })
    },
  }
}
</script>
<style scoped lang="scss">
.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 20px;
}
.circle_green{
  background: #00ff00;
}
.circle_red{
  background: #ff0000;
}
</style>