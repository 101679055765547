var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":"临时服务人员","close-on-click-modal":false,"visible":_vm.isOpenModal,"append-to-body":"","width":"1000px"},on:{"update:visible":function($event){_vm.isOpenModal=$event},"closed":_vm.closeModal}},[_c('div',{staticStyle:{"width":"100%"}},[_c('ch-button',{on:{"click":_vm.openAddTag}},[_vm._v("新增")]),_c('ch-table',{attrs:{"render-option":_vm.tableOption,"data":_vm.tableList,"loading":_vm.isTableLoading,"props":{width: '100%', height: '300px', size: 'mini'}},scopedSlots:_vm._u([{key:"available",fn:function(scope){return [(scope.row.isAvailable===1)?_c('div',{staticClass:"circle circle_green"}):_c('div',{staticClass:"circle circle_red"})]}},{key:"handle",fn:function(scope){return [_c('ch-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.openEdit(scope.row)}}},[_vm._v("编辑")]),(scope.row.status===0)?_c('ch-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.handleRecoverStop(scope.row)}}},[_vm._v("停用")]):_vm._e(),(scope.row.status===1)?_c('ch-button',{attrs:{"type":"link"},on:{"click":function($event){return _vm.handleRecoverStop(scope.row)}}},[_vm._v("恢复")]):_vm._e(),(scope.row.status===2)?_c('ch-button',{attrs:{"type":"delete"},on:{"click":function($event){return _vm.toExamine(scope.row)}}},[_vm._v("审核")]):_vm._e(),(scope.row.canDelete===1 || scope.row.status===2 || scope.row.status===3)?_c('ch-button',{attrs:{"type":"delete"},on:{"click":function($event){return _vm.handleDelete(scope.row)}}},[_vm._v("删除")]):_vm._e(),(scope.row.status===0||scope.row.status===1)?_c('ch-button',{attrs:{"type":"link"},on:{"click":()=>{
          _vm.$router.push({name:'serviceStaffTakeOrderRecord',query:{id:scope.row.id}})
          _vm.isOpenModal = false
        }}},[_vm._v("接单记录")]):_vm._e(),(scope.row.status===0||scope.row.status===1)?_c('ch-button',{attrs:{"type":"link"},on:{"click":()=>{
          _vm.$router.push({name:'serviceStaffFlow',query:{id:scope.row.id}})
          _vm.isOpenModal = false
        }}},[_vm._v("资金流水")]):_vm._e()]}}])})],1),_c('div',{staticClass:"flex_con",attrs:{"slot":"footer"},slot:"footer"},[_c('ch-page',{ref:"page",attrs:{"type":"old","getListFunction":_vm.getList}})],1),_c('modal',{ref:"tagsModal",attrs:{"getListFunction":_vm.getList}}),_c('el-dialog',{attrs:{"title":"审核临时服务人员","close-on-click-modal":false,"visible":_vm.isOpenExamineModal,"append-to-body":"","width":"500px"},on:{"update:visible":function($event){_vm.isOpenExamineModal=$event},"closed":_vm.closeExamineModal}},[_c('ch-form',{ref:"examineFormRef",staticStyle:{"width":"100%"},attrs:{"render-option":_vm.examineOption,"model":_vm.examineForm,"rules":_vm.rulesExamine,"props":{ labelWidth: '120px', paddingRight: '10px' }}}),_c('div',{staticClass:"flex_con",attrs:{"slot":"footer"},slot:"footer"},[_c('ch-button',{attrs:{"type":"cancel"},on:{"click":_vm.closeExamineModal}},[_vm._v("取消")]),_c('ch-button',{attrs:{"type":"ok","loading":_vm.isExamineLoading},on:{"click":_vm.handleExamine}},[_vm._v("确定")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }